import { fetch } from '../services/fetch'
import { setMessage, SUCCESS, ERROR } from '../modules/messages'
import { push } from 'connected-react-router'
import { toParams } from '../services/utils'
import { tokenHandler } from './auth'

export const CREATE = 'users/CREATE'
export const FETCH = 'users/FETCH'
export const FETCH_ALL = 'users/FETCH_ALL'
export const SAVE = 'users/SAVE'
export const DELETE = 'users/DELETE'
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const FETCH_ORGS = 'users/FETCH_ORGS'

// TODO: accountId existe apenas para se enquadrar no padrão do TableWrap.
// Pensar em algo melhor, para poder tirar daqui.
export const fetchUsers = (accountId, params = {}) => {
  const query = toParams(params)
  const request = fetch(`/users${query}`).then(res => res.json())
  return {
    type: FETCH_ALL,
    payload: request,
  }
}

export const updateUserRole = (user, data) => (
  (dispatch) => {
    const request = fetch(`/users/${user.id}`, { method: 'PUT', body: JSON.stringify({ role: data.role }) })
      // const request = fetch(`/users/${user.id}/role`, { method: 'PUT', body: { role: data.role } })
      .then(tokenHandler(dispatch))
      .then(res => {
        console.log(res.data);
        dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'))
        dispatch(push('/settings/profile'));
        return res.json()
      })
      .catch(res => res)

    return dispatch({
      type: SAVE,
      payload: request,
    })
  }
)

export const updateUserRoleList = (userId, data) => (
  (dispatch) => {
    const request = fetch(`/users/${userId}`, { method: 'PUT', body: JSON.stringify({ role: data.role }) })
      .then(res => {
        console.log(res.data);
        dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'));
        dispatch(push('/users'));
        return res.json()
      })
      .catch(res => res)

    return dispatch({
      type: SAVE,
      payload: request,
    })
  }
)

export const fetchUser = (userId, remoteControl) => {
  const request = fetch(`/users/${userId}`).then(res => res.json())

  if (remoteControl) {
    return request
  }

  return {
    type: FETCH,
    payload: request,
  }
}

export const saveUser = (userId, data) => (
  (dispatch) => {
    const request = fetch(`/users/${userId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data)
      })
      // TODO: Analisar necessidade do tokenHandler (Necessitando de alteração)
      // .then(tokenHandler(dispatch))
      .then(res => {
        if (res) {
          console.log("atualizar role");
          dispatch(setMessage(SUCCESS, 'Atualizado com sucesso!'))
          // dispatch(push('/users'))

          return res.json()
        }
      })
      .catch(err => {
        dispatch(setMessage(ERROR, 'Ocorreu um erro.'))

        throw err
      })

    return dispatch({
      type: SAVE,
      payload: request,
    })
  }
)

export const create = (accountType, data) => (
  (dispatch) => {
    const request = fetch(`/users/${data.person ? data.person : 'pj'}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json()).catch(res => {
      let message = ''

      if (res.description) {
        message = res.description.details
      }
      if (res.errors) {
        message = res.errors.map(msg => msg.message)
      }
      if (!res.errors && !res.description) {
        message = 'Ocorreu um erro, contate o suporte técnico.'
      }
      dispatch(setMessage(ERROR, message))
    })
    return dispatch({
      type: CREATE,
      payload: request,
    })
  }
)

export const removeUser = (userId) => (
  (dispatch) => {
    const request = fetch(`/users/${userId}`, { method: 'DELETE' })
      .then(() => {
        dispatch(setMessage(SUCCESS, 'Removido com sucesso!'))
        dispatch(push('/users'))
        dispatch(fetchUsers())
      })
      .catch(res => {
        dispatch(setMessage(ERROR, res.message))
      })

    return dispatch({
      type: DELETE,
      payload: request,
    })
  }
)

export const changePassword = (data, userId) => (
  (dispatch) => {
    const request = fetch(`/users/${userId}/password`, {
      method: 'POST',
      body: JSON.stringify({
        old_password: data.old_password,
        new_password: data.new_password,
      }),
    })
      .then(tokenHandler(dispatch))
      .then(res => {
        dispatch(push('/settings/profile'))

        dispatch(setMessage(SUCCESS, 'Senha alterada com sucesso!'))

        return res.json()
      })
      .catch(res => res)

    return dispatch({
      type: CHANGE_PASSWORD,
      payload: request,
    })
  }
)

export const fetchUsersOrganizations = (userId, params = {}, remoteControl) => {
  const query = toParams(params);

  const request = fetch(`/users/${userId}/list-organizations${query}`)
    .then(res => res.json())
    .catch(error => error);

  if (remoteControl) {
    return request;
  }

  return {
    type: FETCH_ORGS,
    payload: request
  };
};
