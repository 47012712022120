import React from 'react';
import ReactDatePicker from 'react-flatpickr';
import classnames from 'classnames';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

export class DatePicker extends React.PureComponent {
    render() {
        const { options, ...props } = this.props;

        console.log("options.defaultDate", options.defaultDate);

        return (
            <div className={classnames('datepicker-wrapper', {
                'hide-picker': options.hideInput,
            })}>
                <ReactDatePicker
                    options={{
                        locale: Portuguese,
                        time_24hr: true,
                        defaultDate: options.defaultDate || new Date().toUTCString(),
                        ...options,
                    }}

                    {...props}
                />
            </div>
        );
    }
}
