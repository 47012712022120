import PropTypes from 'prop-types'
import React from 'react'
import { Card, Loading, Help, AboutSealStatus, ScanStatus } from '../../components'
import { PdfDownloader } from '../../containers'
import { clear } from '../../actions/applications'
import { connect } from 'react-redux'
// import Switch from 'react-toggle-switch';
import { shareReport, cancelShareReport } from '../../actions/awreport'
import moment from 'moment'

const seal = {
  ok: {
    name: 'Ativo',
    color: '#7DC671',
  },
  suspended: {
    name: 'Suspenso',
    color: '#fd4d4d',
  },
  vulnerable: {
    name: 'Vulnerável',
    color: '#ffa8a8',
  },
  default: {
    name: '-',
    color: '#f2f2f2',
  },
}

class ScanReportHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      switched: !!this.props.expirationDate,
      secretKey: this.props.password,
    }
    this.toggleSwitch = this.toggleSwitch.bind(this)
  }

  componentWillUnmount() {
    this.props.clear()
  }

  toggleSwitch() {
    const toShare = !this.state.switched

    if (toShare) {
      this.props.shareReport(this.props.scanId)
    } else {
      this.props.cancelShareReport(this.props.scanId)
    }

    this.setState(oldState => ({ ...oldState, switched: toShare }))
  }

  render() {
    return (
      <Card
        className="scan-details-header column"
        style={{ borderColor: seal[this.props.sealStatus].color }}
      >
        <Loading loading={this.props.applicationDetails.isLoading} />

        <div className="title">
          <h2>
            Detalhes do Scan
          </h2>

          <PdfDownloader scanId={this.props.scanId}/>
        </div>

        <div className="inner">
          <div className="item">
            <div className="type">
              Aplicação
            </div>
            <div className="description">
              <span className="application-name">
                {this.props.name || '-'}
              </span>

              <br />
              {/* <span>{this.props.url}</span> */}
            </div>
          </div>

          <div className="item">
            <div className="type">
              Tipo
            </div>

            <div className="description">
              <div>
                {this.props.profile || '-'}
              </div>
            </div>
          </div>

          <div className="item">
            <div className="type">
              Status
            </div>

            <div className="description">
              <ScanStatus status={this.props.status} />
            </div>
          </div>

          <div className="item">
            <div className="type">
              Iniciado em
            </div>

            <div className="description">
              <div>
                {this.props.initiated
                  ? moment(this.props.initiated).subtract(3, 'hours').format('DD/MM/YYYY [às] HH:mm')
                  : '-'
                }
              </div>
            </div>
          </div>

          <div className="item">
            <div className="type">
              Finalizado em
            </div>

            <div className="description">
              <div>
                {this.props.finished
                  ? moment(this.props.finished).subtract(3, 'hours').format('DD/MM/YYYY [às] HH:mm')
                  : '-'
                }
              </div>
            </div>
          </div>

          <div className="item">
            <div className="type">
              <p>
                Status do Selo
              </p>

              <Help title="Status do Selo">
                <AboutSealStatus />
              </Help>
            </div>

            <div className="description">
              <span className={`mark-${this.props.sealStatus}`} />

              <div>
                {seal[this.props.sealStatus].name}
              </div>
            </div>
          </div>

          {/* <div className="item">
                        <div className="type">
                            Compartilhar Relatório
                            <Help title="Compartilhar Relatório">
                                <AboutSealStatus />
                            </Help>
                        </div>
                        <div className="description">
                            <Switch onClick={this.toggleSwitch} on={this.state.switched} />
                            {/* TODO: pegar href do portal */}
          {/* <AwReportBox
                                url={`https://app.siteblindado.com/awreport/${this.props.scanId}`}
                                password={this.props.password}
                            >
                                {this.state.switched &&
                                    <a className="reportlink">
                                        Visualizar link
                                    </a>
                                }
                            </AwReportBox>
                        </div>
                    </div> */}
        </div>
      </Card>
    )
  }
}

ScanReportHeader.defaultProps = {
  sealStatus: 'default',
}

ScanReportHeader.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  scanId: PropTypes.string,
  expirationDate: PropTypes.string,
  password: PropTypes.string,
  clear: PropTypes.func,
  profile: PropTypes.string,
  status: PropTypes.string,
  sealStatus: PropTypes.string,
  applicationDetails: PropTypes.object,
  initiated: PropTypes.string,
  finished: PropTypes.string,
  shareReport: PropTypes.func,
  cancelShareReport: PropTypes.func,
}

const mapStateToProps = ({ applicationDetails, awreport }) => ({
  applicationDetails,
  awreport,
})

export default connect(mapStateToProps, { clear, shareReport, cancelShareReport })(ScanReportHeader)
