import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { StoreBanner, CardGroup, Card, CardProduct, Loading } from '../../components'
import { fetchStoreProducts } from '../../actions/products'
import { modalFalse, modalTrue } from '../../actions/modal'
import { onlyAdmin } from '../../services/auth'
import Swal from "sweetalert2";
// import Modal from 'react-responsive-modal';

const stores = {
  'site-blindado': 'site_blindado',
  'trust-sign': 'trust_sign',
  blindagem: 'blindagem',
  api: 'api',
  serasa: 'serasa',
  trial: 'trial',
}

const BlindagemAPICard = onlyAdmin(props => (
  <CardProduct
    {...props}
    catalog={props.catalog}
    position={{
      left: props.lastColumn,
      bottom: props.lastLine,
    }}
  />
))

class StoreCatalog extends React.Component {
  constructor(props) {
    super(props)
    this.lastLine = this
      .lastLine
      .bind(this)
    this.showContractAlert = this
      .showContractAlert
      .bind(this)

    this.acceptContractAlert = this
      .acceptContractAlert
      .bind(this)

    this.state = {}
  }

  componentDidMount () {
    if (!this.props.isLoading) {
      this
        .props
        .fetchStoreProducts(stores[this.props.catalog])
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.catalog !== this.props.catalog) {
      this
        .props
        .fetchStoreProducts(stores[nextProps.catalog])
    }
  }

  // eslint-disable-next-line no-undef
  onCloseModal = () => {
    this
      .props
      .modalFalse()
  };

  lastLine (item) {
    const total = this.props.allIds.length
    return item > total - (total % 3 || 3)
  }

  lastColumn (item) {
    return !(item % 3)
  }

  acceptContractAlert = () => {
    try {
      localStorage.setItem('contractAlert', true);
    } catch (error) {
      console.log(error);
    }
  }


  showContractAlert = () => {

    const contractAlert = localStorage.getItem('contractAlert');

    console.log(contractAlert)

    if (contractAlert === "true") {
      return ""
    } else {
      Swal.fire({
        title: "Prezado cliente, <strong>ATENÇÃO</strong>",
        icon: "info",
        iconColor: '#74B61B',
        confirmButtonText: "Entendido",
        confirmButtonColor: '#74B61B',
        html: `
        <div style="text-align: justify;font-size: 1.5rem;">
          Informamos que atualizamos nosso <strong>Contrato Padrão de Cessão de Uso de Software Via Internet</strong>,
          que passará a ser identificado como "<strong>Versão 2</strong>" e passará a ter vigência a partir do dia
          <strong>22/12/2024</strong> e poderá ser acessado através 
          <a 
          href="https://www.siteblindado.com/consumidor/contratos-de-servicos" 
          target="_blank" >
          deste link
          </a>.
        </div>
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          this.acceptContractAlert()
        }
      });
    }

  }

  render () {
    // const bg = {
    //     overlay: {
    //         background: '#00000',
    //         opacity: 0.48,
    //     },
    // };
    return (
      <section className="store-catalog">
        <Loading loading={this.props.isLoading} />
        <StoreBanner
          catalog={this.props.catalog}
          website={this.props.website}
          websiteTitle={this.props.websiteTitle}
          bannerURL={this.props.banner}
          title={this.props.name}
          description={this.props.description}
        />

        {this.showContractAlert()}
        <section className="content">
          <CardGroup>
            {this
              .props
              .allIds
              .map((id, key) => (
                <Card
                  key={`${id}`}
                  style={{
                    ...!!(this.props.catalog === 'trial') && {
                      width: '48%',
                    },
                  }}
                >
                  {/* altera o conteúdo do modal (banner quando loga na página) */}
                  {/* <Modal
                      open={this.props.modal.open}
                      onClose={this.onCloseModal}
                      styles={bg}
                      center
                  >
                      <h1>
                          <b>
                              <u>Aviso Importante!</u>
                          </b>
                      </h1>
                      <h2>
                          <p>Nosso time está trabalhando em home office de segunda à sexta
                              das 08:00 às 19:00 horas, para garantir a entrega do monitoramento
                              da segurança da sua aplicação.
                          </p>
                          <p>Caso tenha alguma dúvida ou solicitação nos
                              acione preferencialmente via e-mail:
                              suporte@siteblindado.com.br ou envie seu
                          contato através do campo “AJUDA” no canto inferior direito da sua tela.
                          </p>
                          <p>Estamos à disposição,</p>
                          <p>Equipe Site Blindado.</p>
                      </h2>
                  </Modal> */}
                  {this.props.results[id].name === "Blindagem de API" ?
                    <BlindagemAPICard {...this.props.results[id]} catalog={this.props.catalog} lastColumn={this.lastColumn(key + 1)} lastLine={this.lastLine(key + 1)} />
                    : <CardProduct
                      {...this.props.results[id]}
                      catalog={this.props.catalog}
                      position={{
                        left: this.lastColumn(key + 1),
                        bottom: this.lastLine(key + 1),
                      }}
                    />
                  }
                </Card>
              ))}
          </CardGroup>
        </section>
      </section>
    )
  }
}

StoreCatalog.propTypes = {
  fetchStoreProducts: PropTypes.func,
  isLoading: PropTypes.bool,
  catalog: PropTypes.string,
  website: PropTypes.string,
  websiteTitle: PropTypes.string,
  banner: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  results: PropTypes.object,
  allIds: PropTypes.array,
  modalFalse: PropTypes.any,
  modal: PropTypes.any,
}

const mapStateToProps = ({ storeProducts, modal }) => ({
  ...storeProducts,
  modal,
})

export default connect(mapStateToProps, { fetchStoreProducts, modalFalse, modalTrue })(StoreCatalog)
