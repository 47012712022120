import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { fetchProducts, fetchSummaryProducts, setProductStatus, chooseProduct, productForm } from '../../actions/products'
import { closeSlider } from '../../actions/slider'
import { SliderButton } from '../../containers'
import { userIsAdmin } from '../../services/auth'
import { withAccountId } from '../AccountContainer'
import {
  TableWrap,
  TableWrapActions,
  TableWrapRight,
  TableWrapLeft,
  Dropdown,
  Button,
  Card,
  RefundProduct,
  Help,
} from '../../components'
import { MenuItem } from '@mui/material'
import Swal from 'sweetalert2';
import ProductOriginBadge from '../../components/ProductOriginBadge';
import ProductContractIdText from '../../components/ProductContractIdText';
import moment from 'moment'

const CancelSlider = (props) => (
  <SliderButton
    selector={props.selector}
    className={props.className}
    button="Cancelar"
    sliderTitle="Cancelar Produto"
  >
    <RefundProduct
      product={props.description}
      product_id={props.id}
    />
  </SliderButton>
)


const expireProduct = (props) => {
  props.setProductStatus(props.id, 'expired')
  props.fetchSummaryProducts(props.accountId)
}

const Approvement = userIsAdmin((props) => (
  <Dropdown
    classes="button-pure"
    button={
      <div className="button-pure">
        Ações
      </div>
    }
  >
    <MenuItem>
      <a
        className="controls-links"
        onClick={() => {

          Swal.fire({
            title: 'Aprovar Contrato',
            html: `
            <div style="
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;">
              <div style="
              display: flex;
              flex-direction: column;
              align-content: center;
              justify-content: center;
              margin-bottom: 2rem;
              ">  
                <label for="contract-id">ID do Contrato*:</label>
                <input id="contract-id" class="swal2-input" placeholder="Digite o ID do contrato">
              </div>
              <div style="
              display: flex;
              flex-direction: column;
              align-content: center;
              justify-content: center;
              ">  
                <label for="justification">Justificativa:</label>
                <textarea id="justification" class="swal2-textarea" placeholder="Digite uma justificativa"></textarea>
              </div>
            </div>
            `,
            focusConfirm: false,
            preConfirm: () => {
              const contractId = document.getElementById('contract-id').value;
              const justification = document.getElementById('justification').value;

              if (!contractId) {
                Swal.showValidationMessage('O campo ID do contrato é obrigatório');
              }

              return { contractId, justification };
            },
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#74b61b',
          }).then((result) => {
            if (result.isConfirmed) {
              const { contractId, justification } = result.value;
              props.setProductStatus(props.id, 'available', '', contractId, justification)
              props.fetchSummaryProducts(props.accountId)
            }
          });

        }}
      >
        Aprovar
      </a>
    </MenuItem>

    <MenuItem>
      <a
        className="controls-links"
        onClick={() => {
          props.setProductStatus(props.id, 'denied')
          props.fetchSummaryProducts(props.accountId)
        }}
      >
        Negar
      </a>
    </MenuItem>

    <MenuItem>
      <a
        className="controls-links"
        onClick={() => {
          Swal.fire({
            title: 'Atenção',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            icon: 'warning',
            iconColor: '#74B61B',
            html:
              `Você está prestes a expirar este crédito, tem certeza que deseja continuar?`,
            confirmButtonColor: '#74B61B'
          }).then((result) => {
            if (result.isConfirmed) {
              expireProduct(props)
            }
          })
        }}
      >
        Expirar
      </a>
    </MenuItem>

    <MenuItem>
      <CancelSlider {...props} />
    </MenuItem>
  </Dropdown>
))

const toEmit = (props) => {
  const url = {
    ssl: '/certificates/new',
    aw: '/armour/new',
    api: '/armour/scan-api/new',
    waf: '/waf/new',
    serasa: '/serasa/new',
  }
  props.productForm(props)
  props.dispatch(chooseProduct(props.id, url[props.product_type.category], props))
}

const AvailableActions = userIsAdmin((props) => (
  <Dropdown
    classes="button-pure"
    button={
      <div className="button-pure">
        Ações
      </div>
    }
  >
    <MenuItem>
      <a
        className="controls-links"
        onClick={() => toEmit(props)}
      >
        Emitir
      </a>
    </MenuItem>

    <MenuItem>
      <a
        className="controls-links"
        onClick={() => {
          Swal.fire({
            title: 'Atenção',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            icon: 'warning',
            iconColor: '#74B61B',
            html:
              `Você está prestes a expirar este crédito, tem certeza que deseja continuar?`,
            confirmButtonColor: '#74B61B'
          }).then((result) => {
            if (result.isConfirmed) {
              expireProduct(props)
            }
          })
        }}
      >
        Expirar
      </a>
    </MenuItem>

    <MenuItem>
      <CancelSlider {...props} />
    </MenuItem>
  </Dropdown>
), (props) => (
  <Button
    pure
    onClick={() => toEmit(props)}
  >
    Emitir
  </Button>
))

const AvailableActionsWaf = userIsAdmin((props) => (
  <Dropdown classes="button-pure" button="Ações">
    <ul className="list list-vertical">
      <li>
        <a
          className="controls-links"
          onClick={() => toEmit(props)}
        >
          {props.max_domains !== null && props.max_domains > 1 ? 'Informar domínios' : 'Informar domínio'}
        </a>
      </li>
      <li>
        <CancelSlider {...props} />
      </li>
    </ul>
  </Dropdown>
), (props) => (
  <Button
    pure
    onClick={() => toEmit(props)}
  >
    Informar domínio
  </Button>
))

const AvailableActionsSerasa = userIsAdmin((props) => (
  <Dropdown classes="button-pure" button="Ações">
    <MenuItem>
      <a
        className="controls-links"
        onClick={() => toEmit(props)}
      >
        Solicitar certificado
      </a>
    </MenuItem>

    <MenuItem>
      <CancelSlider {...props} />
    </MenuItem>
  </Dropdown>
))

const Actions = (props) => {
  if (props.status === 'pending' && !props.is_subproduct) {
    return <Approvement {...props} />
  }

  if (props.status === 'available') {
    if (props.category === 'waf') {
      return <AvailableActionsWaf {...props} />
    }
    if (props.category === 'serasa') {
      return <AvailableActionsSerasa {...props} />
    }
    return <AvailableActions {...props} />
  }

  return false
}

const ActionsContainer = connect(null, { setProductStatus, chooseProduct, productForm, fetchSummaryProducts })(Actions)

const StatusFilter = (props) => {
  const status = {
    pending: 'Pendente',
    available: 'Disponível',
    denied: 'Negado',
    canceled: 'Cancelado',
    in_use: 'Em uso',
    used: 'Usado',
    expired: 'Expirado',
  }

  return (
    <div className={classnames('badge', { pending: props.item === 'pending', denied: props.item === 'denied' })}>
      {status[props.item]}

      {!!props.observation && props.item === 'canceled' &&
        <Help title="Motivo do cancelamento">
          <p>{props.observation}</p>
        </Help>
      }
    </div>
  )
}

const CategoryFilter = (props) => {
  const category = {
    aw: 'Blindagem',
    ssl: 'SSL',
  }

  return (
    <div className={classnames('badge', { pending: props.item === 'pending', denied: props.item === 'denied' })}>
      {category[props.item]}
    </div>
  )
}

class ProductsList extends React.Component {
  constructor(props) {
    super(props)

    this.model = {
      id: {
        name: 'ID',
        renderer: (item, obj) => <a href={`/store/product/${obj.id}`}>{item}</a>,
        visible: props.auth.isAdmin
      },
      'category': {
        name: 'Categoria',
        renderer: (item, obj) => <CategoryFilter status={this.props} item={item} />,
        filterItems: {
          aw: 'Blindagem',
          ssl: 'SSL',
        },
      },
      'product_type.name': { name: 'Tipo de Produto' },
      full_description: {
        name: 'Descrição',
      },
      status: {
        name: 'Status',
        renderer: (item, obj) => <StatusFilter status={this.props} item={item} observation={obj.observation} />,
        filterItems: {
          pending: 'Pendente',
          available: 'Disponível',
          denied: 'Negado',
          canceled: 'Cancelado',
          in_use: 'Em uso',
          used: 'Usado',
        },
      },
      date_created: {
        name: 'Solicitado em',
        renderer: (date) => moment(date).subtract(3, 'hours').format('DD/MM/YYYY [às] HH:mm:ss'),
      },
      contract_id: {
        name: 'Contrato',
        renderer: (item, obj) => <ProductContractIdText contractId={obj.contract_id} />,
        visible: props.auth.isAdmin
      },
      origin: {
        name: 'Origem',
        renderer: (item, obj) => <ProductOriginBadge origin={obj.origin} />,
        visible: props.auth.isAdmin
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (action, obj) => <ActionsContainer accountId={this.props.accountId}  {...obj} />,
      },
    }
  }

  render () {
    return (
      <article>
        <Card>
          <TableWrap
            model={this.model}
            accountId={this.props.accountId}
            source={this.props.clientProducts}
            actionFetch={this.props.fetchProducts}
            hasSummary
            summaryFetch={this.props.fetchSummaryProducts}
            location={this.props.location}
            orderBy={['status', 'date_created']}
          >
            <TableWrapActions>

              <TableWrapLeft>
                Lista de Produtos
              </TableWrapLeft>

              <TableWrapRight>
                <Link to="/store/site-blindado">
                  Solicitar Produto
                </Link>
              </TableWrapRight>

            </TableWrapActions>
          </TableWrap>
        </Card>
      </article>
    )
  }
}

CancelSlider.propTypes = {
  id: PropTypes.string,
}

Actions.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  is_subproduct: PropTypes.bool,
  category: PropTypes.string,
}

StatusFilter.propTypes = {
  item: PropTypes.string,
  observation: PropTypes.string,
}

ProductsList.propTypes = {
  auth: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  fetchProducts: PropTypes.func,
  clientProducts: PropTypes.object,
  productForm: PropTypes.func,
  push: PropTypes.func,
  location: PropTypes.object,
}

const mapStateToProps = ({ auth, clientProducts, productInfo, summaryProducts, dispatch }) => ({ auth, clientProducts, productInfo, summaryProducts, dispatch })

export default connect(mapStateToProps, { fetchProducts, fetchSummaryProducts, push, closeSlider, productForm })(withAccountId(ProductsList))



// import React, {useEffect} from 'react'
// import {connect, useDispatch, useSelector} from 'react-redux'
// import {Link, useHistory} from 'react-router-dom'
// import classnames from 'classnames'
// import {chooseProduct, fetchProducts, fetchSummaryProducts, productForm, setProductStatus} from '../../actions/products'
// import {SliderButton} from '../../containers'
// import {userIsAdmin} from '../../services/auth'
// import {
//   Button,
//   Card,
//   Dropdown,
//   Help,
//   RefundProduct,
//   TableWrap,
//   TableWrapActions,
//   TableWrapLeft,
//   TableWrapRight,
// } from '../../components'
// import {MenuItem} from '@mui/material'
// import {closeSlider} from "../../actions/slider";
//
// export default function ProductsList(props) {
//   const dispatch = useDispatch()
//   const history = useHistory()
//
//   const account = useSelector(state => state.account)
//   const clientProducts = useSelector(state => state.clientProducts)
//   const accountId = account[0] ? account[0].id : undefined
//
//   function getClientProducts() {
//     dispatch(fetchProducts(accountId))
//   }
//
//   function getSummaryProducts() {
//     dispatch(fetchSummaryProducts(accountId))
//   }
//
//   useEffect(() => {
//     if (!clientProducts.isLoading) {
//       const summaryProducts = fetchSummaryProducts(accountId)
//       dispatch(summaryProducts)
//     }
//   }, [accountId])
//
//   const url = {
//     ssl: '/certificates/new',
//     aw: '/armour/new',
//     api: '/armour/scan-api/new',
//     waf: '/waf/new',
//     serasa: '/serasa/new',
//   }
//
//   const CancelSlider = (props) => (
//     <SliderButton
//       selector={props.selector}
//       className={props.className}
//       button="Cancelar"
//       sliderTitle="Cancelar Produto"
//     >
//       <RefundProduct
//         product={props.observation}
//         refundProduct={observation => {
//           dispatch(setProductStatus(props.id, 'canceled', observation))
//           return dispatch(fetchSummaryProducts(accountId))
//         }}
//         closeSlider={() => closeSlider()}
//       />
//     </SliderButton>
//   )
//   const Approvement = userIsAdmin((props) => (
//     <Dropdown
//       classes="button-pure"
//       button={
//         <div className="button-pure">
//           Ações
//         </div>
//       }
//     >
//       <MenuItem>
//         <a
//           className="controls-links"
//           onClick={() => {
//             dispatch(setProductStatus(props.id, 'available'))
//             dispatch(fetchSummaryProducts(accountId))
//           }}
//         >
//           Aprovar
//         </a>
//       </MenuItem>
//
//       <MenuItem>
//         <a
//           className="controls-links"
//           onClick={() => {
//             dispatch(setProductStatus(props.id, 'denied'))
//             dispatch(fetchSummaryProducts(accountId))
//           }}
//         >
//           Negar
//         </a>
//       </MenuItem>
//
//       <MenuItem>
//         <CancelSlider {...props} />
//       </MenuItem>
//     </Dropdown>
//   ))
//
//   const AvailableActions = userIsAdmin((props) => (
//     <Dropdown classes="button-pure" button={<div className="button-pure"> Ações </div>}>
//       <MenuItem>
//         <a className="controls-links" onClick={() => toEmit(props)}> Emitir </a>
//       </MenuItem>
//       <MenuItem>
//         <CancelSlider {...props} />
//       </MenuItem>
//     </Dropdown>), (props) => (
//     <Button pure onClick={() => toEmit(props)}> Emitir </Button>
//   ))
//
//   const AvailableActionsWaf = userIsAdmin((props) => (
//     <Dropdown classes="button-pure" button="Ações">
//       <ul className="list list-vertical">
//         <li>
//           <a
//             className="controls-links"
//             onClick={() => toEmit(props)}
//           >
//             {props.max_domains !== null && props.max_domains > 1 ? 'Informar domínios' : 'Informar domínio'}
//           </a>
//         </li>
//         <li>
//           <CancelSlider {...props} />
//         </li>
//       </ul>
//     </Dropdown>
//   ), (props) => (
//     <Button
//       pure
//       onClick={() => toEmit(props)}
//     >
//       Informar domínio
//     </Button>
//   ))
//
//   const AvailableActionsSerasa = userIsAdmin((props) => (
//     <Dropdown classes="button-pure" button="Ações">
//       <MenuItem>
//         <a
//           className="controls-links"
//           onClick={() => toEmit(props)}
//         >
//           Solicitar certificado
//         </a>
//       </MenuItem>
//
//       <MenuItem>
//         <CancelSlider {...props} />
//       </MenuItem>
//     </Dropdown>
//   ))
//
//   const Actions = (props) => {
//     if (props.status === 'pending' && !props.is_subproduct) {
//       return <Approvement {...props} />
//     }
//
//     if (props.status === 'available') {
//       if (props.category === 'waf') {
//         return <AvailableActionsWaf {...props} />
//       }
//       if (props.category === 'serasa') {
//         return <AvailableActionsSerasa {...props} />
//       }
//       return <AvailableActions {...props} />
//     }
//
//     return false
//   }
//
//   const ActionsContainer = connect(null, {setProductStatus, chooseProduct, productForm, fetchSummaryProducts})(Actions)
//
//   async function toEmit(obj) {
//     dispatch(productForm(accountId))
//     dispatch(chooseProduct(obj.id))
//     history.push(url[obj.product_type.category])
//   }
//
//   const model = {
//     'product_type.name': {name: 'Tipo de Produto'},
//     full_description: {
//       name: 'Descrição',
//     },
//     status: {
//       name: 'Status',
//       renderer: (item, obj) => {
//         const status = {
//           pending: 'Pendente',
//           available: 'Disponível',
//           denied: 'Negado',
//           canceled: 'Cancelado',
//           in_use: 'Em uso',
//           used: 'Usado',
//           expired: 'Expirado',
//         }
//         return (
//           <div className={classnames('badge', {pending: item === 'pending', denied: item === 'denied'})}>
//             {status[item]}
//
//             {!!obj.observation && item === 'canceled' &&
//               <Help title="Motivo do cancelamento">
//                 <p>{obj.observation}</p>
//               </Help>
//             }
//           </div>
//         )
//       },
//       filterItems: {
//         pending: 'Pendente',
//         available: 'Disponível',
//         denied: 'Negado',
//         canceled: 'Cancelado',
//         in_use: 'Em uso',
//         used: 'Usado',
//       },
//     },
//     date_created: {
//       name: 'Solicitado em',
//       renderer: (date) => new Date(date).toLocaleDateString('pt-BR'),
//     },
//     actions: {
//       name: ' ',
//       className: 'actions',
//       renderer: (action, obj) => <ActionsContainer accountId={accountId}  {...obj} />,
//     },
//   }
//
//   return (
//     <article>
//       <Card>
//         <TableWrap
//           model={model}
//           accountId={accountId}
//           source={clientProducts}
//           actionFetch={getClientProducts}
//           hasSummary={false}
//           summaryFetch={getSummaryProducts}
//           location={props.location}
//           orderBy={['status']}
//         >
//           <TableWrapActions>
//
//             <TableWrapLeft>
//               Lista de Produtos
//             </TableWrapLeft>
//
//             <TableWrapRight>
//               <Link to="/store/site-blindado">
//                 Solicitar Produto
//               </Link>
//             </TableWrapRight>
//
//           </TableWrapActions>
//         </TableWrap>
//       </Card>
//     </article>
//   )
// }
