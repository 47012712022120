import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { RRule } from 'rrule'
import { Button } from '../../components'
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Alert
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { connect, useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import { sendScheduling } from '../../actions/applications'

import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'
import { Typography } from '@material-ui/core'

const FormScheduling = (props) => {
  const dispatch = useDispatch()
  const account = useSelector((state) => state.auth)
  const [hasRestrictArea, setHasRestrictArea] = useState(false)
  const [dateTimeError, setDateTimeError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmedPassword, SetShowConfirmedPassword] = useState(false)
  const [swagger, setSwagger] = useState(undefined)
  const { handleSubmit, errors = {}, control } = useForm()

  const dontHaveEditPermission = account.data.role !== 'admin'

  const scanData = props.source.scan_profiles.filter(
    (scan) => scan.name === props.scanType
  )[0]

  useEffect(() => {
    const data = scanData.scan_parameters

    const hasParameters = scanData.scan_parameters && scanData.scan_parameters.logged_scan

    if (hasParameters) {
      setHasRestrictArea(true)
      setSwagger(data.swagger)
    }
  }, [scanData])

  const getUTCDate = () => {
    const utcDate = new Date(props.date)

    return utcDate.toISOString()
    return new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes(),
      utcDate.getUTCSeconds()
    )
  }

  const onSubmit = (data) => {
    moment.locale('en')

    if (validateDateAndTime(props.date)) {
      setDateTimeError(true);
      return
    }

    const weekDay = moment.weekdays(props.date).toUpperCase().slice(0, 2)

    moment.locale('pt-BR')

    const { source, scanProfileId } = props

    const freq = {
      // TEMP até receber o tipo do profile
      'Scan de Aplicação': RRule.WEEKLY,
      'Scan de Servidor': RRule.WEEKLY,
      'PenTest Automatizado': RRule.YEARLY,
    }

    let freqByType = RRule.WEEKLY

    if (freq[props.scanType]) {
      freqByType = freq[props.scanType]
    }

    const rrule = new RRule({
      freq: freqByType,
      dtstart: new Date(props.date),
      ...(freqByType === RRule.WEEKLY && { byweekday: RRule[weekDay] }),
    })

    return dispatch(
      sendScheduling(scanProfileId, {
        scan_profile_id: scanProfileId,
        ...data,
        ...(props.type === 'edit' && { schedule: rrule.origOptions }),
        ...(props.type === 'unit' && { datetime: getUTCDate() }),
        ...(source.category === 'api' && swagger),
        has_restrict_area: hasRestrictArea,
      })
    )
  }

  const validateDateAndTime = (date) => {
    setDateTimeError(false);
    const currentDate = new Date();
    const inputDate = new Date(date);
    const [formatCurrentDate, formatCurrentTime] = currentDate.toISOString().split('T');
    const [formatInputDate, formatInputTime] = inputDate.toISOString().split('T');

    if (formatCurrentDate === formatInputDate) {
      const [currentHour, currentMinutes] = formatCurrentTime.split(':').map(Number);
      const [inputHour, inputMinutes] = formatInputTime.split(':').map(Number);

      if (inputHour > currentHour ||
        (inputHour === currentHour && inputMinutes > currentMinutes)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const getGenge = () => {
    if (props.scanType === 'PenTest Automatizado') {
      return 'todo dia'
    }

    const weekDay = moment.weekdays(props.date)

    return ['sábado', 'domingo'].includes(weekDay)
      ? 'todos os'
      : 'todas as'
  }

  const handleSelectJson = async (event) => {
    const reader = new FileReader()

    reader.onload = (e) => setSwagger(e.target.result)

    reader.readAsText(event.target.files[0])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      {dateTimeError && (
        <Alert severity="warning" sx={{ display: "flex", alignItems: "center" }}>
          <span><strong>Por favor, verifique o horário inserido. Certifique-se de que está correto e dentro do período permitido.</strong></span>
        </Alert>
      )}

      <div className="scheduling__obs" style={{ marginBottom: 15 }}>
        {props.type === 'unit' && (
          <span>
            O scan será executado no próximo dia
            <strong className="scheduling__info">
              <span> </span>
              {moment(props.date).format('DD/MM/YYYY')}
            </strong>
          </span>
        )}

        {props.type === 'edit' && (
          <span>
            O scan será executado {getGenge()}{' '}

            <strong className="scheduling__info">
              {moment(props.date)
                .format(props.scanType === 'PenTest Automatizado'
                  ? 'DD [de] MMMM [às] HH[h]mm'
                  : 'dddd [às] HH[h]mm')
              }
            </strong>
          </span>
        )}
      </div>

      {props.type === 'edit' && (
        <div>
          <h3>Dados da aplicação: </h3>

          <div className="scheduling__obs" style={{ marginBottom: 15 }}>
            <div className="field">
              <div className="fields-container">
                <label htmlFor="name">Domínio</label>
                <Controller
                  render={({ field }) =>
                    <input
                      {...field}
                      className={`input ${errors.name ? 'input-error' : ''
                        }`}
                      type="text"
                      disabled
                    />
                  }
                  defaultValue={props.source.name ? props.source.name : ''}
                  name="name"
                  control={control}
                />
              </div>
            </div>

            <div className="field">
              <div className="fields-container">
                <label htmlFor="path">Path</label>
                <Controller
                  render={({ field }) =>
                    <input
                      {...field}
                      className={`input ${errors.path ? 'input-error' : ''
                        }`}
                      type="text"
                    />
                  }
                  disabled={dontHaveEditPermission}
                  defaultValue={scanData.scan_parameters ? scanData.scan_parameters.path : ''}
                  name="path"
                  control={control}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {props.source.category === 'api' && (
        <div>
          <h3>Endpoints: </h3>
          <div
            className="scheduling__obs"
            style={{ marginBottom: 15 }}
          >
            {dontHaveEditPermission && (
              <div className="field">
                <div className="fields-container">
                  <label htmlFor="swagger">Endpoints</label>
                  <input
                    className={`input ${errors.swagger ? 'input-error' : ''
                      }`}
                    type="file"
                    accept=".json"
                    onChange={handleSelectJson}
                    required
                    disabled={dontHaveEditPermission}
                  />
                </div>
              </div>
            )}
            <div className="field">
              <div className="fields-container">
                <Collapse
                  in={!!swagger}
                  timeout="auto"
                  unmountOnExit
                >
                  <pre>
                    <code>{swagger}</code>
                  </pre>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      )}

      {(props.type === 'edit' && props.scanType !== 'Scan de Servidor (SDS)') &&
        <div>
          <FormControlLabel
            color="primary"
            control={
              <Checkbox
                defaultValue={false}
                checked={hasRestrictArea}
                color="primary"
                onChange={() => setHasRestrictArea(!hasRestrictArea)}
                name="hasRestrictArea"
              />
            }
            disabled={dontHaveEditPermission}
            label="É um dominio com área restrita?"
          />

          <Collapse in={hasRestrictArea} timeout="auto" unmountOnExit>
            <h3>Dados de área restrita: </h3>
            <div className="scheduling__obs" style={{ marginBottom: 15 }}>
              <div className="field">
                <div className="fields-container">
                  <label htmlFor="auth_path">
                    Path da área restrita:
                  </label>

                  <Controller
                    render={({ field }) =>
                      <input
                        {...field}
                        className={`input ${errors.auth_path
                          ? 'input-error'
                          : ''
                          }`}
                        type="text"
                      />
                    }
                    disabled={dontHaveEditPermission}
                    defaultValue={scanData.scan_parameters
                      ? scanData.scan_parameters.auth_path
                      : ''
                    }
                    name="auth_path"
                    control={control}
                  />
                </div>
              </div>

              <div className="field">
                <div className="fields-container">
                  <label htmlFor="username">
                    Usuário
                  </label>

                  <Controller
                    render={({ field }) =>
                      <input
                        {...field}
                        className={`input ${errors.username ? 'input-error' : ''
                          }`}
                        type="text"
                        autoComplete="new-password"
                      />
                    }
                    name="username"
                    defaultValue={scanData.scan_parameters
                      ? scanData.scan_parameters.logged_scan_user
                      : ''
                    }
                    disabled={dontHaveEditPermission}
                    control={control}
                  />
                </div>
              </div>

              <div className="field">
                <div className="fields-container">
                  <label htmlFor="password">
                    Senha
                  </label>

                  <div className="scheduling__field__icon">
                    <Controller
                      render={({ field }) =>
                        <input
                          {...field}
                          className={`input ${errors.password
                            ? 'input-error'
                            : ''
                            }`}
                          type={
                            showPassword
                              ? 'text'
                              : 'password'
                          }
                          style={{ width: '90%' }}
                          autoComplete="new-password"
                        />
                      }
                      defaultValue={scanData.scan_parameters
                        ? scanData.scan_parameters.logged_scan_pwd
                        : ''
                      }
                      name="password"
                      disabled={dontHaveEditPermission}
                      control={control}
                    />

                    <IconButton
                      onClick={() =>
                        setShowPassword(!showPassword)
                      }
                      color="primary"
                      disabled={dontHaveEditPermission}
                    >
                      {showPassword ? (
                        <VisibilityOff color="primary" />
                      ) : (
                        <Visibility color="primary" />
                      )}
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="fields-container">
                  <label htmlFor="confirm_password">
                    Repita a senha
                  </label>
                  <div className="scheduling__field__icon">
                    <Controller
                      render={({ field }) =>
                        <input
                          {...field}
                          className={`input ${errors.confirm_password
                            ? 'input-error'
                            : ''
                            }`}
                          type={
                            showConfirmedPassword
                              ? 'text'
                              : 'password'
                          }
                          style={{ width: '90%' }}
                        />
                      }
                      name="confirm_password"
                      defaultValue={scanData.scan_parameters
                        ? scanData.scan_parameters.logged_scan_pwd
                        : ''
                      }
                      disabled={dontHaveEditPermission}
                      control={control}
                    />

                    <IconButton
                      onClick={() =>
                        SetShowConfirmedPassword(
                          !showConfirmedPassword
                        )
                      }
                      color="primary"
                      disabled={dontHaveEditPermission}
                    >
                      {showPassword ? (
                        <VisibilityOff color="primary" />
                      ) : (
                        <Visibility color="primary" />
                      )}
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      }

      {props.type === 'edit' && (
        <p>
          <i>
            Obs: Ao agendar um novo scan, os scans já agendados
            serão substituídos.
          </i>
        </p>
      )}

      {props.type === 'unit' && (
        <p>
          <i>
            Ao realizar este agendamento será executado um scan
            extra somente no dia selecionado.
          </i>
        </p>
      )}

      <Button
        className="button-scheduling-form"
        loading={props.submitting}
      >
        Salvar
      </Button>
    </form>
  )
}

FormScheduling.propTypes = {
  scanType: PropTypes.string,
  submitting: PropTypes.bool,
  date: PropTypes.string,
  handleSubmit: PropTypes.func,
  children: PropTypes.element,
  source: PropTypes.object,
  type: PropTypes.string,
  swagger: PropTypes.string,
}

const Form = reduxForm({
  form: 'formScheduling',
})(FormScheduling)

export default connect(null, { sendScheduling })(Form)
