import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router';
import { withAccountId } from '../AccountContainer';
import {
  Card, Help, Loading,
} from '../../components';
import { fetchProduct, upgradeProduct, downgradeProduct } from '../../actions/products';
import classnames from 'classnames';
import ProductContractIdText from '../../components/ProductContractIdText';
import ProductOriginBadge from '../../components/ProductOriginBadge';
import { fetchApplications } from '../../actions/applications';
import Swal from 'sweetalert2';
import moment from 'moment'

const StatusFilter = (props) => {
  const status = {
    pending: 'Pendente',
    available: 'Disponível',
    denied: 'Negado',
    canceled: 'Cancelado',
    in_use: 'Em uso',
    used: 'Usado',
    expired: 'Expirado',
  };

  return (
    <div className={classnames('badge', {
      bg_yellow: props.item === 'pending',
      bg_green: props.item === 'available',
      bg_red: props.item === 'denied',
      bg_brown: props.item === 'canceled',
      bg_cyan: props.item === 'in_use',
      bg_blue: props.item === 'used',
      bg_gray: props.item === 'expired',
    })}>
      {status[props.item]}

      {!!props.observation && props.item === 'canceled' &&
        <Help title="Motivo do cancelamento">
          <p>{props.observation}</p>
        </Help>
      }
    </div>
  );
};

class Product extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchProduct(this.props.id);
    this.props.fetchApplications(this.props.accountId, "?page_size=1000");
  }

  renderInfoItem(label, item) {
    return (
      <p><small>{label}:</small> <small>{item}</small></p>
    );
  }

  onUpgradeSubmit(action, product) {
    Swal.fire({
      title: 'Atenção',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      icon: 'warning',
      iconColor: '#faad00',
      customClass: 'swal-fontSize-15rem',
      html:
        `Você está prestes a atualizar o produto ${product?.product_type?.name} para o tipo <b>${action === 'UPGRADE' ? 'Premium' : 'Simples'}</b>! 
            <br><br>Este processo atualizará também todos os Scans atuais vinculados a este crédito de Blindagam.
            <br><br>Digite a frase <b>atualizar produto</b> e clique no botão para confirmar o ${action}.`,
      confirmButtonColor: '#faad00',
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      preConfirm: async (txt) => {
        if (txt == 'atualizar produto') {
          console.log(action.toUpperCase() + ` confirmada`);
          return 'success';
        } else {
          Swal.showValidationMessage(`A mensagem de segurança não foi digitada corretamente.`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (action == 'UPGRADE') {
          this.props.upgradeProduct(this.props.id)
        } else {
          this.props.downgradeProduct(this.props.id)
        }
      }
    })
  }

  renderUpgradeButton(product) {
    if (product &&product?.category === 'aw' && product?.status !== 'canceled' && product?.status !== 'expired') {
      if (product?.product_type?.name.includes('Premium')) {
        return (
          <button className="button-pure" style={{ marginRight: '1rem' }}
                  onClick={() => this.onUpgradeSubmit('DOWNGRADE', product)}>
            Migrar para Produto Simples
          </button>
        );
      } else {
        return (
          <button className="button-pure" style={{ marginRight: '1rem' }}
                  onClick={() => this.onUpgradeSubmit('UPGRADE', product)}>
            Migrar para Produto Premium
          </button>
        );
      }
    }

    return null;
  }

  renderInfo(product) {
    return (
      <Card className={`md-tine-card`}>
        <div className={`product-info-card`}>
          <div style={{ width: '100%' }}>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6rem' }}>
                <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{product?.product_type?.name}</div>
                <small style={{ fontSize: '1rem' }}>{product?.id}</small>
                <small
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}>{product?.category === 'aw' ? 'BLINDAGEM' : product?.category && product?.category.toUpperCase()}</small>
              </div>
              <div><StatusFilter item={product?.status} /></div>
            </div>
            <div style={{ width: '100%' }}>
              <div style={{ marginBottom: '2rem', paddingBottom: '2rem', borderBottom: '1px solid #CCC' }}>
                {this.renderInfoItem('Descrição', product?.full_description)}
                {this.renderInfoItem('Periodo', `${product?.years} ano(s)` ?? `NÃO INFORMADO`)}
                {this.renderInfoItem('Solicitado em', moment(product?.date_created).subtract(3, 'hours').format('DD/MM/YYYY [às] HH:mm:ss'))}
                {this.renderInfoItem('Observação', product?.observation ?? `NÃO INFORMADO`)}
              </div>
              <div style={{ marginBottom: '2rem' }}>
                {this.renderInfoItem('Origem', <ProductOriginBadge origin={product?.origin} />)}
                {this.renderInfoItem('Contrato', product?.contract_id ?? `NÃO INFORMADO`)}
                {this.renderInfoItem('Justificativa', product?.justification ?? `NÃO INFORMADO`)}
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '2rem' }}>
          {this.renderUpgradeButton(product)}
          <Link to="/store/products" className="button-pure">Voltar</Link>
        </div>
      </Card>
    );
  }

  renderApplications(applications) {
    return (
      <Card className={`md-tine-card`}>
        <div className={`product-info-card`}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '4rem' }}>
              <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Produto atribuido para {applications.length > 1 ? 'blindagens' : 'blindagem'}:</div>
            </div>
            {applications.map(app => <Link to={`/armour/applications`} key={app.id} style={{marginBottom: '1rem'}}><small>{app.name}</small></Link>)}
          </div>
        </div>
      </Card>
    );
  }

  render() {
    const { auth, clientProduct, applications, push } = this.props;

    if (!auth.isAdmin) {
      push('/store/site-blindado');
    }

    console.log('applications', applications);

    let applicationsArray = [];
    applications.allIds.map(id => {
      console.log('applications.results[id]', applications.results[id]);
      applicationsArray.push(applications.results[id]);
    });

    console.log('applicationsArray', applicationsArray);

    const applicationsFetched = applicationsArray.filter(app => app.product_id === this.props.id);

    console.log("applicationsFetched", applicationsFetched);

    return (
      <article>
        <Loading loading={clientProduct.isLoading} />
        {clientProduct.result !== null && this.renderInfo(clientProduct.result)}
        {applicationsFetched.length > 0 && this.renderApplications(applicationsFetched)}
      </article>
    );
  }
}

Product.defaultProps = {
  id: null,
  auth: null,
  clientProduct: {
    result: null,
    isLoading: false,
  },
  applications: {
    count: 0,
    results: [],
  },
  dispatch: () => {
  },
};

Product.propTypes = {
  id: PropTypes.string,
  auth: PropTypes.shape({
    isAdmin: PropTypes.bool,
  }),
  clientProduct: PropTypes.shape({
    result: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      full_description: PropTypes.string,
      origin: PropTypes.string,
      contract_id: PropTypes.string,
      justification: PropTypes.string,
      status: PropTypes.string,
      max_domains: PropTypes.number,
      domains_in_use: PropTypes.number,
      category: PropTypes.string,
      is_subproduct: PropTypes.bool,
      observation: PropTypes.string,
      years: PropTypes.string,
      expiration: PropTypes.string,
      date_created: PropTypes.string,
      last_updated: PropTypes.string,
      product_type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        category: PropTypes.string,
      }),
    }),
    applications: PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.array,
    }),
    isLoading: PropTypes.bool,
  }),
};

const mapStateToProps = ({ auth, clientProduct, applications, dispatch }) => ({ auth, clientProduct, applications, dispatch });

export default connect(mapStateToProps, {
  fetchProduct,
  fetchApplications,
  upgradeProduct,
  downgradeProduct,
  push,
})(withAccountId(Product));
